import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
 
class LaxButton extends React.Component {

    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this)
        lax.addElement(this.el)
    }
    
    componentWillUnmount() {
        lax.removeElement(this.el)
    }

    render(){
        return (
            <Link to="/orateurs" style={{marginLeft:"120px",marginTop:"20px"}}className="btn btn-primary lax" >
                {this.props.buttonText}
            </Link>
        );
    }
}

LaxButton.defaultProps = {
    buttonText: 'Voir plus de orateurs'
};
 
export default LaxButton;
import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/PlatinumSponsors';
import GoldSponsors from '../LaxButton/GoldSponsors';
import logo2 from '../../assets/images/logo2.png';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        400: {
            items:3,
        },
        576: {
            items:3,
        },
        768: {
            items:4,
        },
        1200: {
            items:5,
        }
    }
}
const options2 = {
    loop: false,
    nav: false,
    dots: false,
    autoplayHoverPause: false,
    autoplay: false,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        400: {
            items:3,
        }, 576: {
            items:3,
        }
    }
}

 
 
class Partner extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    

    render(){
        const partners = [
            { name: 'Ministère de la Santé', logo: require('../../assets/images/logo2.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Le Conseil Régional de Nouakchott', logo: require('../../assets/images/logo3.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Faculté de Médecine, de Pharmacie et d’Odonto-Stomatologie', logo: require('../../assets/images/logo4.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Caisse Nationale d’Assurance Maladie', logo: require('../../assets/images/logo5.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Centrale d’Achat des Médicaments et consommables', logo: require('../../assets/images/logo6.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Centre HOspitzlier National', logo: require('../../assets/images/logo7.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Centre Hospitalier Mere-Enfant', logo: require('../../assets/images/logo8.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Centre National de Cardiologie', logo: require('../../assets/images/logo9.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Centre National d’Oncologie', logo: require('../../assets/images/logo10.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Centre Hospitalier des Spécialités', logo: require('../../assets/images/logo11.png') , style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Hopital de l’Amitié', logo:require('../../assets/images/logo12.png') , style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Hôpital Cheikh Zayed', logo: require('../../assets/images/logo13.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Institut National d’Hepato-virologie', logo: require('../../assets/images/logo14.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Ecole Nationale supérieure des Sciences de la Santé', logo: require('../../assets/images/logo15.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Institut National de Recherche en Santé Publique', logo: require('../../assets/images/logo16.png') , style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Clinique Chiva', logo: require('../../assets/images/logo17.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Yata mèdical', logo: require('../../assets/images/logo23.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },

            { name: 'Clinique Ibn Sina', logo: require('../../assets/images/logo18.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Clinique Medipole', logo: require('../../assets/images/logo20.png') , style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Clinique Kissi', logo: require('../../assets/images/logo32.png') , style: { width: '110px', height: '90px', marginTop: '10px' }},

            { name: 'Clinique Ettewfigh', logo: require('../../assets/images/logo19.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Clinique EL Menar', logo: require('../../assets/images/logo33.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
             { name: 'Clinique ', logo: require('../../assets/images/47.jpeg'), style: { width: '110px', height: '90px', marginTop: '10px' } },

         
          ];
          const partners2 = [
            { name: 'Centre National d’Oncologie', logo: require('../../assets/images/logo24.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Ministère de la Santé', logo: require('../../assets/images/platinum-partner1.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Le Conseil Régional de Nouakchott', logo: require('../../assets/images/platinum-partner2.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Faculté de Médecine, de Pharmacie et d’Odonto-Stomatologie', logo: require('../../assets/images/platinum-partner3.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Caisse Nationale d’Assurance Maladie', logo: require('../../assets/images/platinum-partner4.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Centrale d’Achat des Médicaments et consommables', logo: require('../../assets/images/platinum-partner5.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Clinique Medipole', logo: require('../../assets/images/logo30.png') , style: { width: '110px', height: '90px', marginTop: '10px' }},

            { name: 'Clinique Medipole', logo: require('../../assets/images/logo31.png') , style: { width: '90px', height: '70px', marginTop: '10px' }},

            { name: 'Centre HOspitzlier National', logo: require('../../assets/images/platinum-partner6.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Centre Hospitalier Mere-Enfant', logo: require('../../assets/images/platinum-partner7.png'), style: { width: '110px', height: '90px', marginTop: '10px' }},
            { name: 'Centre National de Cardiologie', logo: require('../../assets/images/platinum-partner8.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            { name: 'Clinique EL Menar', logo: require('../../assets/images/logo33.png'), style: { width: '110px', height: '90px', marginTop: '10px' } },
             { name: 'Clinique ', logo: require('../../assets/images/47.jpeg'), style: { width: '110px', height: '90px', marginTop: '10px' } },
            
          ];
      
          const groupSize = 5; // Nombre de partenaires par groupe
          const groupedPartners = [];
      
          for (let i = 0; i < partners.length; i += groupSize) {
            groupedPartners.push(partners.slice(i, i + groupSize));
          }
        return (
            <section className="partner-area ptb-120">
                <div className="container">
                    <div className="section-title">
                         <h2> Sponsors et Partners </h2>

 
                    </div>
                    
                   


                    
       <div className="row">
       <div className="col-lg-12">
                            <div className="partner-title platinum-sponsor">
                                <GoldSponsors />
                            </div>
                        </div>
                        <br/><br/><br/>
        {partners.map((partner, index) => (
          <div key={index} className="col-lg-2">
            <div className="text-center">
              <img
                style={partner.style} 
                src={partner.logo}
                alt={`Logo ${partner.name}`}
              />
              <p style={{ fontSize: '14px', marginTop: '10px' }}>{partner.name}</p>
            </div>
          </div>
        ))}
     </div>                    <br/><br/>

     <div className="row">
                        <div className="col-lg-12">
                            <div className="partner-title platinum-sponsor">
                                <PlatinumSponsors />
                            </div>
                        </div>
                        {partners2.map((partner, index) => (
          <div key={index} className="col-lg-2">
            <div className="text-center">
              <img
                style={partner.style} 
                src={partner.logo}
                alt={`Logo ${partner.name}`}
              />
             </div>
          </div>
        ))}
                              
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Partner;
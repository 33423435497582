import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';
import Footer from '../Common/Footer';

class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };

    // ... (les méthodes onSubmit, nameChangeHandler, etc.)

    render() {
        // Créez un tableau de contacts avec les détails de chaque contact.
        const contacts = [
            {
                name: "Pr I Khalifa",
                title: "President",
                image: require("../../assets/images/11.jpg")
            },
            {
                name: "Pr A FEIL",
                title: "Vice-président",
                image: require("../../assets/images/6.jpg")
            },
            {
                name: "Dr. BENINA",
                title: "Vice-président",
                image: require("../../assets/images/25.jpeg")
            },
            {
                name: "Dr.M SIDATT",
                title: "Secrétaire général",
                image: require("../../assets/images/17.jpg")
            },
            {
                name: "Dr N ABDALLAHI",
                title: "Secrétaire général adjoint",
                image: require("../../assets/images/18.jpg")
            },
            {
                name: "Dr A NDIAY",
                title: "Trésorier",
                image: require("../../assets/images/46.jpeg")
            },
            {
                name: "Dr Hamady MOUSTAPHA",
                title: "Trésorier adjoint",
                image: require("../../assets/images/27.jpeg")
            },
            {
                name: "Dr A BA",
                title: "Assesseurs",
                image: require("../../assets/images/28.jpeg")
            },
            {
                name: "Dr A MOHAMED",
                title: "Assesseurs",
                image: require("../../assets/images/24.jpeg")
            },
            {
                name: "Dr A BIHA",
                title: "Assesseurs",
                image: require("../../assets/images/45.jpeg")
            },
            {
                name: "Dr A MOGUEYA",
                title: "Assesseurs",
                image: require("../../assets/images/40.jpeg")
            },
            {
                name: "Dr L BEDDI",
                title: "Assesseurs",
                image: require("../../assets/images/41.jpeg")
            },
            // Ajoutez d'autres contacts ici...
        ];
        

        // Divisez les contacts en groupes de 3.
        const contactGroups = [];
        for (let i = 0; i < contacts.length; i += 3) {
            contactGroups.push(contacts.slice(i, i + 3));
        }

        return (
            <React.Fragment>
                <div className="page-title-area item-bg1">
                    <div className="container">
                        <h1>Membre du L'équipe SOMAUPED</h1>
                    </div>
                </div>

                <section className="contact-area ptb-120">
                    <div className="container">
                        {contactGroups.map((contactGroup, index) => (
                            <div className="row" key={index}>
                                {contactGroup.map((contact, i) => (
                                    <div style={{marginTop:"130px"}} className="col-lg-4 col-md-6" key={i}>
                                        <div className="contact-box">
                                            <div>
                                                <img
                                                    src={contact.image}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={contact.name}
                                                    alt="Author"
                                                    style={{
                                                        width: '200px',
                                                        height: '180px',
                                                        borderRadius: '100%'
                                                    }}
                                                />
                                            </div>
                                            <div className="content">
                                                <h4 style={{ marginLeft: "50px" }}>{contact.name}</h4>
                                                <p style={{ marginLeft: "55px" }}>{contact.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Contact;

import React from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/logo2.png';


class Navigation extends React.Component {

    state = {
        collapsed: true,
        isOpen: false
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    componentDidUpdate(nextProps) {
        if (this.props.match.path !== nextProps.match.path) {
            // this.onRouteChanged();
            console.log('OK')
        }
    }
 
    onRouteChanged = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <header id="header"  className="header-area">
                <div  id="navbar"  >
                    <nav     className="navbar navbar-expand-md navbar-light">
                        <div className="container">
                       {/*  <Link className="navbar-brand" to="/home-two">
                                        <div>
                                            <img style={{ width: '65px', height: '65px' }} src={logo2} alt="logo" />
                                        </div>
        </Link><div style={{ color: 'white', fontSize: '15px', marginRight: '15px' }}>
                                    <span style={{ fontSize: '20px' }}> وزارة الصحة الموريتانية   </span>
                                    <br />
                                    <span style={{ fontSize: '13px' }}>Ministère mauritanien de la Santé</span>
                                </div>
*/}
                             <Link className="navbar-brand" to="/">
                                <div>
                                    <img style={{ width: '65px', height: '65px' }} src={logo} alt="logo" />
                                </div>
                            </Link>
                         <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ color: '231c1c', fontSize: '10px', marginRight: '15px' }}>
                                    <span style={{ fontSize: '12px' }}>الجمعية الموريتانية لطب الأطفال</span>
                                    <br />
                                    <span style={{ fontSize: '9px' }}>Société Mauritanienne de Pédiatrie</span>
                                </div>
        </div> 
                            {/*  <Link className="navbar-brand" to="/home-two">
                                <div>
                                    <img style={{ width: '95px', height: '95px' }} src={logo2} alt="logo" />
                                    <p style={{ color: 'white', fontSize: '10px' }}>وزارة الصحة الموريتانية  </p>
                                </div>
                            </Link>
        */}

                            <button
                                onClick={this.toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <NavLink
                                            to="/"
                                            className="nav-link"
                                            onClick={this.toggleNavbar}
                                        >
                                            Accueil
                                        </NavLink>


                                    </li>

                                    {/*  <li className="nav-item">
                                        <Link
                                            to="#"
                                            onClick={this.toggleOpen}
                                            className="nav-link"
                                        >
                                            Pages
                                        </Link>

                                        <ul className={menuClass}>
                                            <li className="nav-item">
                                                <Link to="#" className="nav-link">About</Link>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/about-1"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            About 1
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/about-2"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            About 2
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/about-3"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            About 3
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="#" className="nav-link">Pricing</Link>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/pricing-1"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Pricing 1
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/pricing-2"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Pricing 2
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="#" className="nav-link">Speakers</Link>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/speakers-1"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Speakers 1
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/speakers-2"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Speakers 2
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/speakers-3"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Speakers 3
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="#" className="nav-link">Schedule</Link>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/schedule-1"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Schedule 1
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/schedule-2"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Schedule 2
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/schedule-3"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Schedule 3
                                                        </NavLink>
                                                    </li>

                                                    <li className="nav-item">
                                                        <NavLink
                                                            to="/schedule-4"
                                                            className="nav-link"
                                                            onClick={this.toggleNavbar}
                                                        >
                                                            Schedule 4
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/login"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                    target="_blank"
                                                >
                                                    Login
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/signup"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                    target="_blank"
                                                >
                                                    Sign Up
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/coming-soon"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                    target="_blank"
                                                >
                                                    Coming Soon
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/error-404"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    404 Error
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/faq"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    FAQ
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/contact"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Contact
                                                </NavLink>
                                            </li>
                                        </ul>
    </li>*/}

                                    <li className="nav-item">
                                        <Link
                                            to="/orateurs"
                                            className="nav-link"
                                            onClick={this.toggleNavbar}
                                        >
                                            Orateurs
                                        </Link>
                                        {/*}  <ul className={menuClass}>
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/speakers-1"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Speakers 1
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/speakers-2"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Speakers 2
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/speakers-3"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Speakers 3
                                                </NavLink>
                                            </li>
</ul>*/}
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/programme"
                                            className="nav-link"
                                            onClick={this.toggleOpen}
                                        >
                                            Programme
                                        </Link>
                                        {/* <ul className={menuClass}>
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/schedule-1"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Schedule 1
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/schedule-2"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Schedule 2
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/schedule-3"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Schedule 3
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/schedule-4"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Schedule 4
                                                </NavLink>
                                            </li>

</ul>*/}
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            to="/sponsors"
                                            className="nav-link"
                                            onClick={this.toggleNavbar}
                                        >
                                            Sponsor
                                        </NavLink>
                                    </li>

                                    {/* <li className="nav-item">
                                        <Link
                                            to="#"
                                            className="nav-link"
                                            onClick={this.toggleOpen}
                                        >
                                            Blog
                                        </Link>
                                        <ul className={menuClass}>
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/blog-1"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Blog Grid 1
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/blog-2"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Blog Grid 2
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/blog-3"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Blog Right Sidebar 1
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/blog-4"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Blog Right Sidebar 2
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    to="/single-blog"
                                                    className="nav-link"
                                                    onClick={this.toggleNavbar}
                                                >
                                                    Blog Details
                                                </NavLink>
                                            </li>
                                        </ul>
</li>*/}

                                    <li className="nav-item">
                                        <NavLink
                                            to="/membre"
                                            className="nav-link"
                                            onClick={this.toggleNavbar}
                                        >
                                            Membre SOMAUPED
                                        </NavLink>
                                    </li>
                                </ul>

                                <div className="others-option">
                                    
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default withRouter(Navigation);
import React from 'react';
import { Link } from 'react-router-dom';

class MainBanner extends React.Component {
    render() {
        return (
            <div className="page-title-area item-bg5">
                <div className="container">
                    <h1>Orateurs</h1>

                </div>
            </div>
        );
    }
}

export default MainBanner;
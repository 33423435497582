import React from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDIfzVcjaHpdLgJf1OT3t6Ba-8TLzOblbE",
    authDomain: "event-143a1.firebaseapp.com",
    projectId: "event-143a1",
    storageBucket: "event-143a1.appspot.com",
    messagingSenderId: "38103612654",
    appId: "1:38103612654:web:d8de029ef3b78ff14747ff",
    measurementId: "G-9LR27DCLBM"
}; const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
class CountDown extends React.Component {
    constructor(props) {
        super(props)

    }
    state = {
        days: '',
        hours: '',
        minutes: '',
        seconds: ''
    };
    componentDidMount() {

        const fetchData = async () => {
            const dataRef = collection(db, 'test');
            const snapshot = await getDocs(dataRef);

            if (!snapshot.empty) {
                const firstDocument = snapshot.docs[0];
                const data = firstDocument.data();
                const date = data.date;
                this.setState({ date });
            } else {
                // La collection est vide ou aucune image n'a été trouvée
            }
        };

        fetchData();
        this.myInterval = setInterval(() => {
            this.makeTimer();
        }, 1000);
    }
    makeTimer = () => {
        console.log(this.state.date)

        let endTime = new Date("october 13, 2023 8:00:00 AM");
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }
        this.setState({
            days, hours, minutes, seconds
        });
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    render() {
        return (
                     <div className="event-countdown">
                        <div id="timer">
                            <div id="days">{this.state.days} <span>Jours</span></div>
                            <div id="hours">{this.state.hours} <span>Heures</span></div>
                            <div id="minutes">{this.state.minutes} <span>Minutes</span></div>
                            <div id="seconds">{this.state.seconds} <span>Secondes</span></div>
                        </div>
                    </div>
          );
    }
}

export default CountDown;
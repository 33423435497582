import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeDefault from "./components/pages/HomeDefault";
import Navigation from "./components/Navigation/Navigation";
import HomeTwo from "./components/pages/HomeTwo";
import HomeThree from "./components/pages/HomeThree";
import HomeFour from "./components/pages/HomeFour";
import HomeFive from "./components/pages/HomeFive";
import AboutOne from "./components/pages/AboutOne";
import AboutTwo from "./components/pages/AboutTwo";
import AboutThree from "./components/pages/AboutThree";
import PricingOne from "./components/pages/PricingOne";
import PricingTwo from "./components/pages/PricingTwo";
import SpeakersOne from "./components/pages/SpeakersOne";
import SpeakersTwo from "./components/pages/SpeakersTwo";
import SpeakersThree from "./components/pages/SpeakersThree";
import ScheduleOne from "./components/pages/ScheduleOne";
import ScheduleTwo from "./components/pages/ScheduleTwo";
import ScheduleThree from "./components/pages/ScheduleThree";
import ScheduleFour from "./components/pages/ScheduleFour";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import ComingSoon from "./components/pages/ComingSoon";
import NotFound from "./components/pages/NotFound";
import Faq from "./components/pages/Faq";
import Contact from "./components/pages/Contact";
import BlogOne from "./components/pages/BlogOne";
import BlogTwo from "./components/pages/BlogTwo";
import BlogThree from "./components/pages/BlogThree";
import BlogFour from "./components/pages/BlogFour";
import BlogDetails from "./components/pages/BlogDetails";
import Sponsor from "./components/pages/Sponsor";
import Partner from "./components/Common/Partner";

// Conditionally render Navigation
const renderNavigation = () => { 
    if (!( window.location.pathname === '/error-404')) {
        return <Navigation />;
    }
    
}
// navbar.js

// Récupérer l'élément de la barre de navigation par son ID
 

const AppRouter = () => {
    return (
        <Router>
            {renderNavigation()}
            <Switch>
                <Route path="/" exact component={HomeTwo} />
                 
                <Route path="/orateurs" exact component={SpeakersOne} />
                 
                <Route path="/programme" exact component={ScheduleOne} />
            
                
                 
                <Route path="/membre" exact component={Contact} />
                
                 <Route path="/sponsors" exact component={Partner} />
                <Route path="/error-404" exact component={NotFound} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
import React from 'react';
import { Link } from 'react-router-dom';
import LaxDiv from '../Shared/LaxDiv';
import LaxButton from '../Shared/LaxButton';

class SpeakersThree extends React.Component {
    render(){
        return (
            <section className="speakers-area ptb-120">
                  <div className="container">
                    <div className="section-title">
                         <h2> Orateurs</h2>
                        <div className="bar"></div>
                        <LaxDiv text="Speakers" dataPreset="driftRight" />
                    </div>                        

                </div>
             <div className="row m-0">

             <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/11.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr I Khalifa
                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
vaccination anti hpv(Aspects cliniques de l’infection à HPV  )                               </li>
                            </ul>
                        </div>
                    </div>
 

                    
                    <div className="col-lg-3 col-md-6 p-1">
                        <div style={{ width: '300px', height: '350px', position: 'relative' }}   className="single-speakers">
                            <img
                                src={require("../../assets/images/6.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr A Feil
                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
                                Session de cas cliniques et de pédiatrie générale(Thrombophlébite cérébrale)   
                                </li>
                            </ul>
                        </div>
                    </div>  

                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/17.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr M Sidatt

                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
Pédiatrie en maternité(Expérience mauritanienne et perspectives locales) & Carence martiale chez l’enfant                               </li>
                            </ul>
                        </div>
                    </div> <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/18.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Dr Nasser Dine Abdalahi

                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
                                La place du traitement conservateur dans la prise en charge de l'insuffisance rénale chez l'enfant.                                </li>
                            </ul>
                            
                        </div>
                    </div>
                    </div>                                              

                    <LaxButton />
            </section>
        );
    }
}
 
export default SpeakersThree;
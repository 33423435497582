import React from 'react';
import { Link } from 'react-router-dom';
 
class EventSchedulesOne extends React.Component {
      
    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
        
    }
    render(){
        const handleDownload = () => {
            const pdfFilePath = process.env.PUBLIC_URL + '/assets/images/programme.pdf';
            window.open(pdfFilePath, '_blank');
        }
        return (  
            <section className="schedule-area bg-image ptb-120">
           
              


 
                             <br/>
                <div  className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab">
                                <ul className="tabs active">
                                    <li
                                        onClick={(e) => this.openTabSection(e, 'tab1')}
                                        className="current"
                                    >
                                        <Link to="#">
                                        Premier Jour
                                            <span>Vendredi 13 October 2023</span>
                                        </Link>
                                    </li>

                                    {/*<li onClick={(e) => this.openTabSection(e, 'tab2')}>
                                        <Link to="#">
                                            Second Day
                                            <span>5 April 2020</span>
                                        </Link>
        </li>*/}

                                    <li onClick={(e) => this.openTabSection(e, 'tab3')}>
                                        <Link to="#">
                                        Deuxième Jour
                                            <span>Samedi 14 October 2023</span>
                                        </Link>
                                    </li>

                                     
                                </ul>

                                <div className="tab_content">
                                    <div id="tab1" className="tabs_item">
                                        <ul className="accordion">
                                        
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>ACCUEIL & INSCRIPTION </h3>   
      <div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}} className="icofont-wall-clock"> </i> 8:30 - 9:00 </div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                              
                                            </li>
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li >
  <div>
      <h3 style={{marginTop:'25px'}}>SESSIONS PARALLELES
 </h3>   
    <h3 style={{marginTop:'25px'}}>Communications orales : C1 à C11
 </h3>  
 
    <div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}} className="icofont-wall-clock"> </i>9H-10H30                            <i style={{marginLeft:'20px', fontSize:"20px"}} class="icofont-home"></i>Modérateurs : M Bezeid, A Kane, I
Khalifa </div>

    </div> 
    
    <div>
     <h3 style={{marginTop:'25px'}}>Atelier : Réanimation du nouveau-né en
 
salle de naissance </h3>  
    <div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}} className="icofont-wall-clock"> </i>9H-10H30</div>

 
 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div style={{ textAlign: 'center', marginRight: '20px' }}>
    <img
      src={require("../../assets/images/5.jpg")}
      data-toggle="tooltip"
      data-placement="top"
      title="Steven Smith"
      alt="Author"
      style={{ width: '90px', height: '90px', borderRadius: '100%' }}
    />
    <div>Pr M Doagi</div>
  </div>
   

  <div style={{ textAlign: 'center' ,marginRight: '20px'}}>
    <img
      src={require("../../assets/images/6.jpg")}
      data-toggle="tooltip"
      data-placement="top"
      title="Jane Smith"
      alt="Author"
      style={{ width: '90px', height: '90px', borderRadius: '100%' ,marginRight: '12px'}}
    />
    <div style={{marginRight: '12px'}}>Pr A Feil</div>
  </div>
  <div style={{ textAlign: 'center',marginRight: '20px' }}>
    <img
      src={require("../../assets/images/17.jpg")}
      data-toggle="tooltip"
      data-placement="top"
      title="Jane Smith"
      alt="Author"
      style={{ width: '90px', height: '90px', borderRadius: '100%' }}
    />
    <div>Pr M Sidatt</div>
  </div>
</div>
</div> 
    
   
   
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                               
                                            </li>
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>PAUSE CAFÉ ET VISITE DE STANDS ET POSTERS (P1-P9)
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 10H30-11H </div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>SESSION DE NEONATOLOGIE </h3>  
      <div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}} className="icofont-wall-clock"> </i>11H-13H                           <i style={{marginLeft:'20px', fontSize:"20px"}} class="icofont-home"></i>Modérateurs : J Haddad, M Doagi, M Mokhtari, F Abdelkader, A Feil, M Sidatt</div>

 
    </div>
  </li>
  
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
  <div>
      <h3 style={{marginTop:'25px'}}>TABLE RONDE : PEDIATRIE EN MATERNITE 

</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 11H-12H </div>
    </div>
  </li>
  <br/>
  <div className="row">
  <div className="col-lg-2">
       <img
        src={require("../../assets/images/5.jpg")}
        data-toggle="tooltip"
        data-placement="top"
        title="Steven Smith"
        alt="Author"
        style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '30px' }}
      />
      <p className="myname"  style={{ fontSize: '14px', marginTop: '10px' }}>Pr M Doagi</p>
   </div>

  <div className="col-lg-8">
    <h3 style={{ fontSize: '22px' }}>Impact des niveaux de soins et des réseaux périnataux sur la mortalité néonatale</h3>
    <div >
      <i  className="icofont-wall-clock"></i> 11H - 11H:20
    </div>
  </div>
</div>
<br/>
  
    

  <div className="row">
  <div className="col-lg-2">
       <img
        src={require("../../assets/images/speakers1.jpg")}
        data-toggle="tooltip"
        data-placement="top"
        title="Steven Smith"
        alt="Author"
        style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '6px' }}
      />
      <p  className="myname" style={{ fontSize: '14px', marginTop: '10px' }}>K Elvilali</p>
   </div>

  <div className="col-lg-8">
  <h3 style={{marginTop:'25px',fontSize: '22px'}}> Collaboration obstétrico-pédiatrique </h3>   
      <div  > <i  className="icofont-wall-clock"> </i> 11H:20 - 11H:40 </div>
  </div>
</div><br/>
<div className="row">
  <div className="col-lg-2">
       <img
        src={require("../../assets/images/17.jpg")}
        data-toggle="tooltip"
        data-placement="top"
        title="Steven Smith"
        alt="Author"
        style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '6px' }}
      />
      <p className="myname"  sstyle={{ fontSize: '14px', marginTop: '10px' }}>Pr M Sidatt</p>
   </div>
   <div className="col-lg-8">
  <h3 style={{marginTop:'25px',fontSize: '22px'}}>Pédiatrie en maternité : Expérience mauritanienne et perspectives
     </h3> <div  > <i  className="icofont-wall-clock"> </i> 11H:40 - 12H:00  </div>
 </div> </div>

  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
  <div>
      <h3 style={{marginTop:'25px'}}> MISES AU POINT EN NÉONATOLOGIE

</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 12H-13H </div>
    </div>
  </li>
  <br/>
  <div className="row">
  <div className="col-lg-2">
       <img
        src={require("../../assets/images/9.jpg")}
        data-toggle="tooltip"
        data-placement="top"
        title="Steven Smith"
        alt="Author"
        style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '30px' }}
      />
      <p   style={{ fontSize: '14px', marginTop: '10px', marginRight:"10px"}}>Josef Haddad</p>
   </div>

  <div className="col-lg-8">
    <h3 style={{ fontSize: '22px' }}>Les convulsions néonatales </h3>
    <div>
      <i className="icofont-wall-clock"></i> 12H - 12H:20
    </div>
  </div>
</div>
<br/>
  
    

  <div className="row">
  <div className="col-lg-2">
       <img
        src={require("../../assets/images/15.jpg")}
        data-toggle="tooltip"
        data-placement="top"
        title="Steven Smith"
        alt="Author"
        style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '6px' }}
      />
      <p style={{ fontSize: '14px', marginTop: '10px' ,marginLeft:"5px"}}>M Mokhtari</p>
   </div>

  <div className="col-lg-8">
  <h3 style={{marginTop:'25px',fontSize: '22px'}}> Alimentation du nouveau-né de faible poids de naissance </h3>   
      <div  > <i  className="icofont-wall-clock"> </i> 12H:20 - 12H:40 </div>
  </div>
</div><br/>
<div className="row">
  <div className="col-lg-2">
       <img
        src={require("../../assets/images/19.jpg")}
        data-toggle="tooltip"
        data-placement="top"
        title="Steven Smith"
        alt="Author"
        style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '6px' }}
      />
      <p style={{ fontSize: '14px', marginTop: '10px' ,marginLeft:"5px"}}>R Cohen</p>
   </div>
   <br/>
   <div className="col-lg-8">
  <h3 style={{marginTop:'25px',fontSize: '22px'}}>Vaccination du prématuré 
     </h3> <div  > <i  className="icofont-wall-clock"> </i> 12H:40 - 13H:00  </div>
 </div> </div>

  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>PAUSE DEJEUNER
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 13H-15H</div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>TABLE RONDE VACCINATION ANTI HPV</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 15H-16H30
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs: M Bouskraoui, R Cohen, M S Afif, M M Ely Mahmoud, F
Abdelkader, I Khalifa

                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/11.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Pr I Khalifa"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>Pr I Khalifa</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Aspects cliniques de l'infection à HPV</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 15H - 15h35
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/3.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="A Bonahi"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>A Bonahi</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Cancer du col en Mauritanie</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 15h35 - 15H50
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/21.jpeg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="PEV"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>Dr MOHAMEDOU MOHAMED AHMED</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Vaccination HPV en Mauritanie : Etat des lieux</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 12H:40 - 13H:00
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/4.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="PEV"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>Pr M Bouskraoui</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Comment améliorer la couverture vaccinale
</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 12H:40 - 13H:00
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>PAUSE CAFÉ
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 16H30-16H45 </div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>SEANCE D’INAUGURATION OFFICIELLE 
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 16H45-17H30 </div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>SSESSION DE PEDIATRIE GENERALE</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 17H30-19H
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs: K Menif, M Telmoudi, S Barro, SB Sidi Omar, A Ba
                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/34.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="A Samoud"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>A Samoud</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Cas cliniques de pédiatrie générale</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 17H30 - 17H55
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/22.jpg")}
                            data-toggle-="tooltip"
                            data-placement="top"
                            title="K Menif"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>K Menif</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Désordres ioniques chez l'enfant</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 17H55 - 18h30
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/6.jpg")}
                            data-toggle-="tooltip"
                            data-placement="top"
                            title="A Feil"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>A Feil</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Thrombophlébite cérébrale</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 18h30 - 18H40
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/18.jpg")}
                            data-toggle-="tooltip"
                            data-placement="top"
                            title="N Abdellahi"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>N Abdellahi</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{textTransform: 'capitalize',marginTop: '25px', fontSize: '22px' }}>La place du traitement conservateur dans la prise en charge de l'insuffisance rénale chez l'enfant.</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 18H40 - 19H00
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

                                            
                                        </ul>	
                                    </div>

                                    {/*<div id="tab2" className="tabs_item">
                                        <ul className="accordion">
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author">
                                                        <img 
                                                            src={require("../../assets/images/author1.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Smith" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Digital Marketing Theory</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>Gilbert</span> CEO of EnvyTheme</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author author-multi">
                                                        <img 
                                                            src={require("../../assets/images/author5.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Lucy" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author2.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Jonaton Smith" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author3.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="John Smith" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author4.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="John Doe" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Digital World Event Information</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>EnvyTheme Team</span> of USA Inc</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author author-multi">
                                                        <img 
                                                            src={require("../../assets/images/author6.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Lucy" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author7.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Jonaton Smith" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>HTML, CSS and Bootstrap Introduction</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>EnvyTheme Team</span> of USA Inc</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>	
        </div>*/}

<div id="tab3" className="tabs_item">
                                        <ul className="accordion">
                                        
                                           
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li >
  <div>
      <h3 style={{marginTop:'25px'}}>SESSIONS PARALLELES
 </h3>   
    <h3 style={{marginTop:'25px'}}>	Communications orales : C12 à C20


 </h3>  
 
    <div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}} className="icofont-wall-clock"> </i>8H30-9H30                          <i style={{marginLeft:'20px', fontSize:"20px"}} class="icofont-home"></i>Modérateurs : M Athié, A Ndiaye, K Mekhalle, A Feil </div>

    </div> 
    
    <div>
     <h3 style={{marginTop:'25px'}}>Atelier : Traitements inhalés chez l'enfant </h3>  
    <div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}} className="icofont-wall-clock"> </i>8H30-9H30</div>

 
 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
   

  <div style={{ textAlign: 'center' ,marginRight: '20px'}}>
    <img
      src={require("../../assets/images/13.jpg")}
      data-toggle="tooltip"
      data-placement="top"
      title="Jane Smith"
      alt="Author"
      style={{ width: '90px', height: '90px', borderRadius: '100%' ,marginRight: '2px'}}
    />
    <div>H Maouche</div>
  </div>
  <div style={{ textAlign: 'center',marginRight: '20px' }}>
    <img
      src={require("../../assets/images/17.jpg")}
      data-toggle="tooltip"
      data-placement="top"
      title="Jane Smith"
      alt="Author"
      style={{ width: '90px', height: '90px', borderRadius: '100%' }}
    />
    <div>M Sidatt</div>
  </div>
</div>
</div> 
    
   
   
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> 
                                            <li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>TABLE RONDE : PLACE DU FER EN PEDIATRIE</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 9H30-11H
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs : P Tounian, M Mokhtari, A Weddih, E. Zeine, M Sidatt
                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/17.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="M Sidatt"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>M Sidatt</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Carence martiale chez l'enfant : expérience du CHME</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 9H30 - 10H00
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/16.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="P Tounian"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>P Tounian</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Le fer chez l'enfant</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 10H00 - 10H30
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/15.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="M Mokhtari"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>M Mokhtari</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>La carence en fer chez le prématuré</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 10H30 - 11H:00
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>PAUSE CAFÉ ET VISITE DE STANDS ET POSTERS (P10-P18)
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 11H-11H30 </div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>SESSION D’ENDOCRINOLOGIE</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 11H30-12H30
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs : K Menif, A Samoud, I Khalifa, Y Khalef, Z Haidy
                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/12.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Pr Linglart"
                            alt="Auteur"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px' }}>Pr Linglart</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Retard de croissance</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 11H30-12H00
                        </div>
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Vitamine D dans tous ces aspects</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 12H00 - 12H30
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

<li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>SYMPOSIUM PHARMALYS</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 12H30-13H15
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs : K Mnif, M Mokhtari, I Khalifa
                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/5.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ fontSize: '14px', marginTop: '10px', marginLeft: '5px'  }}> Pr M Douagi</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Bases de la nutrition du prématuré</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 12H30-13H15
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>PAUSE DEJEUNER
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 13H15-15H</div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            <li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>SESSION GASTRO-ENTEROLOGIE</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 15H-16H45
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs : A Linglart, H Maouche, Y Mohamed Sghaier, I Khalifa
                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/16.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ marginBottom: '7px', marginLeft: '4px' }}>P Tounian</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Maladie cœliaque</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 15H - 16H35
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/8.jpg")}
                            data-toggle-="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ marginBottom: '14px', marginLeft: '24px' }}>H Haas</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Diarrhée aigüe du nourrisson</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 15H35-16H10
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/10.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ marginBottom: '14px', marginLeft: '22px' }}>A Kane</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Urgence chirurgicales digestives</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 16H10-16H45
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>PAUSE CAFÉ
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 16H45-17H </div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                         
                                          
                                           
                                            
                                          
                                            <li className="accordion-item">
    <Link className="accordion-title" to="#">
        <div className="schedule-info">
            <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <h3 style={{ marginTop: '25px' }}>SESSION INFECTIOLOGIE</h3>
                        <div style={{ fontSize: "15px", marginLeft: '3px' }}>
                            <i style={{ fontSize: "20px" }} className="icofont-wall-clock"></i> 17H-18H45
                            <i style={{ marginLeft: '20px', fontSize: "20px" }} class="icofont-home"></i> Modérateurs :M Bouskraoui, J B Diouf, A Weddih, A Ndiaye, M Sidatt
                        </div>
                    </div>
                </li>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/8.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '13px' }}
                        />
                        <p style={{ marginBottom: '7px', marginLeft: '25px' }}>H Haas</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Antibiothérapie aux urgences pédiatriques</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 17H00-17H35
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/4.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ marginBottom: '14px', marginLeft: '2px' }}>Pr Bouskraoui</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Bon usage des antibiotiques</h3>
                        <div className='mydate'>
                            <i className="icofont-wall-clock"></i> 17H35-18H10
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2">
                        <img
                            src={require("../../assets/images/22.jpg")}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Steven Smith"
                            alt="Author"
                            style={{ width: '100px', height: '80px', borderRadius: '100%', marginRight: '10px' }}
                        />
                        <p style={{ marginBottom: '14px', marginLeft: '24px' }}>K Menif</p>
                    </div>
                    <div className="col-lg-8">
                        <h3 style={{ marginTop: '25px', fontSize: '22px' }}>Intérêt de l’échographie thoracique en pédiatrie</h3>
                        <div>
                            <i className="icofont-wall-clock"></i> 18H10-18H45
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </Link>
</li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                 
                                                
                                                    <div className="schedule-info">
                                                       

                                                    <ul>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    
    <div>
      <h3 style={{marginTop:'25px'}}>CLOTURE DU CONGRES
</h3>   
<div style={{fontSize:"15px",marginLeft:'3px'}} >  <i style={{ fontSize:"20px"}}  className="icofont-wall-clock"> </i> 18H45</div>
    </div>
  </li>
  
</ul>

                                                    </div>
                                                </Link>
                                                
                                                
                                            </li>
                                            
                                        </ul>	
                                    </div>

                                    <div id="tab4" className="tabs_item">
                                        <ul className="accordion">
                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author">
                                                        <img 
                                                            src={require("../../assets/images/author1.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Smith" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Digital Marketing Theory</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>Roberto</span> CEO of EnvyTheme</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author author-multi">
                                                        <img 
                                                            src={require("../../assets/images/author5.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Lucy" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author2.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Jonaton Smith" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author3.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="John Smith" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author4.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="John Doe" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Digital World Event Information</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>EnvyTheme Team</span> of USA Inc</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author author-multi">
                                                        <img 
                                                            src={require("../../assets/images/author6.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Lucy" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author7.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Jonaton Smith" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>HTML, CSS and Bootstrap Introduction</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>EnvyTheme Team</span> of USA Inc</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="accordion-item">
                                                <Link className="accordion-title" to="#">
                                                    <div className="author author-multi">
                                                        <img 
                                                            src={require("../../assets/images/author8.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Steven Lucy" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author9.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="Jonaton Smith" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author3.jpg")}
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="John Smith" 
                                                            alt="Author"
                                                        />
                                                        <img 
                                                            src={require("../../assets/images/author4.jpg")} 
                                                            data-toggle="tooltip" 
                                                            data-placement="top" 
                                                            title="John Doe" 
                                                            alt="Author"
                                                        />
                                                    </div>
                                                    
                                                    <div className="schedule-info">
                                                        <h3>Digital World Event Information</h3>

                                                        <ul>
                                                            <li><i className="icofont-user-suited"></i> By <span>EnvyTheme Team</span> of USA Inc</li>
                                                            <li><i className="icofont-wall-clock"></i> 13:00AM - 20:00PM</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                                
                                                <div className="accordion-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took Link galley of type and scrambled it to make Link type specimen book.</p>

                                                    <div className="row h-100 align-items-center">
                                                        <div className="col-lg-6 col-md-7">
                                                            <div className="location">
                                                                <b>Location:</b> Hall 1, Building C , King Street , <span>USA</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-5 text-right">
                                                            <Link to="#" className="btn btn-primary">View Details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>	
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            
                        </div>
                    </div>
                </div>

                <div className="shape1"><img src={require("../../assets/images/shapes/1.png")} alt="shape1" /></div>
                <div className="shape2 rotateme"><img src={require("../../assets/images/shapes/2.png")} alt="shape2" /></div>
                <div className="shape3 rotateme"><img src={require("../../assets/images/shapes/3.png")} alt="shape3" /></div>
                <div className="shape4"><img src={require("../../assets/images/shapes/4.png")} alt="shape4" /></div>
            </section>
        );
    }
}
 
export default EventSchedulesOne;
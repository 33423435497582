import React from 'react';
import { Link } from 'react-router-dom';
import LaxDiv from '../Shared/LaxDiv';

class SpeakersOne extends React.Component {
    render() {
        return (
            <section className="speakers-area ptb-120">
                 <div className="container">
                    <div className="section-title">
                         <h2> Orateurs</h2>
                        <div className="bar"></div>
                        <LaxDiv text="Speakers" dataPreset="driftRight" />
                    </div>                        

                </div>
                <div className="row m-0">
                    
                   
 

                    <div className="col-lg-3 col-md-6 p-1">
                        <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }}>
                            <img style={{width:'100%',height:'100%'}}
                                src={require("../../assets/images/3.jpg")}
                                alt="speakers"
                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr Abdi Ahmed Bonnahi
                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li> 
                                                    Topics:
Vaccination anti hpv(Cancer du col en Mauritanie )</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 p-1">
                        <div style={{ width: '300px', height: '350px', position: 'relative' }} className="single-speakers">
                            <img style={{width:'100%',height:'100%'}}
                                src={require("../../assets/images/4.jpg")}
                                alt="speakers"
                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr M Bouskraoui
                                </Link></h3>
                                <span>Marrakech</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics1:
Vaccination anti hpv(Comment améliorer la couverture vaccinale)<br/> Topics2:Session infectiologie(Bon usage des antibiotiques)                            </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 p-1">
                        <div style={{ width: '300px', height: '350px', position: 'relative' }}  className="single-speakers">
                <img style={{width:'100%',height:'100%'}}
                                src={require("../../assets/images/5.jpg")}
                                alt="speakers"
                                

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr M Doagi</Link></h3>
                                <span>Tunis</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics1: Pédiatrie en maternité(Impact des niveaux de soins et des réseaux périnataux sur la mortalité néonatale)<br/>Topics2:Symposium pharmalys. 

                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 p-1">
                        <div style={{ width: '300px', height: '350px', position: 'relative' }}   className="single-speakers">
                            <img
                                src={require("../../assets/images/6.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr A Feil
                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
                                Session de cas cliniques et de pédiatrie générale(Thrombophlébite cérébrale)   
                                </li>
                            </ul>
                        </div>
                    </div>

                
                    <div className="col-lg-3 col-md-6 p-1">
                        <div style={{ width: '300px', height: '350px', position: 'relative' }}   className="single-speakers">
                            <img
                                src={require("../../assets/images/34.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Dr A Samoud
                                </Link></h3>
                                <span>Tunis</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
                                Session de pédiatrie générale(Cas cliniques de pédiatrie général)   
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                        <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/8.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr H Haas
                                </Link></h3>
                                <span>Monaco</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics1:Session gastro-entérologie(Diarrhée aigüe du nourrisson) .Topics2: Session infectiologie(Antibiothérapie aux urgences pédiatriques )                              </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/9.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr J Haddad</Link></h3>
                                <span>Abidjan</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:Mises au point en néonatalogie(Les convulsions néonatales)


                                </li>
                            </ul>
                        </div>
                    </div><div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/10.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr Ahmed Kane
                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
Session gastro-entérologie(Urgence chirurgicales digestives) 
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/11.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr I Khalifa
                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
vaccination anti hpv(Aspects cliniques de l’infection à HPV  )                               </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/12.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr A Linglart
                                </Link></h3>
                                <span>Paris</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics1:
Session d'endocrinologie (Retard de croissance & Vitamine D dans tous ces aspects ) 
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/13.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />
                            <div className="speakers-content">
                                <h3><Link to="#">Pr H Maouche

                                </Link></h3>
                                <span>Alger</span>
                            </div>
                            <ul>
                                                    <li>
                                                    
 Atelier:Traitements inhalés chez l'enfant(Avec Pr M Sidatt)                               </li>
                            </ul>
                        </div>
                    </div>  <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/22.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr K Menif

                                </Link></h3>
                                <span>Tunis</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics1:Session infectiologie(Intérêt de l’échographie thoracique ).<br/>
                                Topics2:Session de pédiatrie générale(Désordres ioniques chez l’enfant)                              </li>
                            </ul>
                        </div>
                    </div> <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/15.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr M Mokhtari

                                </Link></h3>
                                <span>Paris</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
Mises au point en néonatologie(Alimentation du nouveau-né de faible poids de naissance )&a carence en fer chez le prématuré.                              </li>
                            </ul>
                        </div>
                    </div> 
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/17.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr M Sidatt

                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
Pédiatrie en maternité(Expérience mauritanienne et perspectives locales) & Carence martiale chez l’enfant                               </li>
                            </ul>
                        </div>
                    </div> <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/16.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">P Tounian
                                </Link></h3>
                                <span>Paris</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
SESSION GASTRO-ENTEROLOGIE(Maladie cœliaque )                               </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/18.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Dr Nasser Dine Abdalahi

                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
                                La place du traitement conservateur dans la prise en charge de l'insuffisance rénale chez l'enfant.                                </li>
                            </ul>
                            
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/19.jpg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Pr Robert Cohen

                                </Link></h3>
                                <span>Nouakchott</span>
                            </div>
                            <ul>
                                                    <li>
                                Topics:
                                MISES AU POINT EN NÉONATOLOGIE : Vaccination du prématuré 
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 p-1">
                    <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }} >
                            <img
                                src={require("../../assets/images/21.jpeg")}
                                alt="speakers"
                                style={{ width: '100%', height: '100%',  }}

                            />

                            <div className="speakers-content">
                                <h3><Link to="#">Dr MOHAMEDOU MOHAMED AHMED

                                </Link></h3><span>Nouakchott</span>
                                
                            </div>
                            <ul>
                                                    <li>
                                Topics
<br/>
Topics:
Vaccination anti hpv(Vaccination HPV en Mauritanie : Etat des lieux )                                 </li>
                            </ul>
                        </div>
                    </div> <div className="col-lg-3 col-md-6 p-1">
                        <div className="single-speakers" style={{ width: '300px', height: '350px', position: 'relative' }}>
                            <img     style={{ width: '100%', height: '100%', objectFit: 'cover' }}

                                src={require("../../assets/images/1.jpg")}
                                alt="speakers"
                            />

                            <div className="speakers-content">
                            <div className="content-wrapper">
      <h3><Link to="#">Dr MS Afif</Link></h3>   
      <span>Casablanca</span>
 
    </div>                                
                            </div>
                            
                             
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SpeakersOne;
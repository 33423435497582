import React from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import logo2 from '../../assets/images/logo2.png';
import logo from '../../assets/images/logo.png';
import CountDown from '../HomeTwo/CountDown';

class MainBanner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            date: null, // Initialisez l'état avec une valeur par défaut
            // ...autres états
        };
    }
    state = {
        isOpen: false
    };

    openModal = () => {
        this.setState({ isOpen: true })
    }



    render() {
        return (
            <React.Fragment>
                <ModalVideo
                    channel='youtube'
                    isOpen={this.state.isOpen}
                    videoId='cRXm1p-CNyk'
                    onClose={() => this.setState({ isOpen: false })}
                />

                <div className="main-banner item-bg2">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container" style={{marginBottom: '200px'}}>
                            <div className="main-banner-content banner-content-center">
                            <div className="mylogo" style={{ display: 'flex',marginBottom:"15px", alignItems: 'center' }}>  
             <img  src={logo} alt="logo" />
            <img  src={logo2} alt="logo" />

        

        
     </div>
  

    <div>
        <p  >
        تحت رعاية معالي وزيرة الصحة  </p>
        <p  >
     Sous le patronage de son excellence  la ministre de la santé
        </p>
        <h1  >المؤتمر الوطني السادس عشر لطب الأطفال </h1>
        <h1 >  XVIème Congrès National de PEDIATRIE</h1>
         <ul>
            <li><i className="icofont-compass"></i> Hotel Monotel Dar El Barka, Nouakchott</li>
            <li><i className="icofont-calendar"></i> 13-15 October, 2023</li>
        </ul>
        <div className="button-box">
            

        </div>


    </div>


  

</div>
<div style={{ }}> <CountDown /></div>


                            </div>
                        </div>
                        
                    </div>

                    <div className="shape1">
                        <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
                    </div>
                    <div className="shape2 rotateme">
                        <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
                    </div>
                    <div className="shape3 rotateme">
                        <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
                    </div>
                    <div className="shape4">
                        <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MainBanner;
<div className="main-banner item-bg2">
<div className="d-table">
    <div className="d-table-cell">
        <div className="container">
            <div className="main-banner-content banner-content-center">
                <p>Are you <span>ready</span> to attend?</p>
                <h1>World Advanced <span>Biggest</span> <br /> Conference <b>2</b><b>0</b><b>2</b><b>0</b></h1>
                <ul>
                    <li><i className="icofont-compass"></i> Yellow Street, United State</li>
                    <li><i className="icofont-calendar"></i> 23-27 Jan, 2020</li>
                </ul>
                <div className="button-box">
                    <Link to="#" className="btn btn-primary">Buy Tickets Now!</Link>
                    <Link 
                        onClick={e => {e.preventDefault(); this.openModal()}}
                        to="#" 
                        className="video-btn popup-youtube"
                    >
                        <i className="icofont-ui-play"></i> Watch Pormo Video
                    </Link>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="shape1">
    <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
</div>
<div className="shape2 rotateme">
    <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
</div>
<div className="shape3 rotateme">
    <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
</div>
<div className="shape4">
    <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
</div>
</div>
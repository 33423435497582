import React from 'react';
import lax from 'lax.js';
import LaxButton from '../Shared/LaxButton';

class About extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()

        document.addEventListener('scroll', function (x) {
            lax.update(window.scrollY)
        }, false)

        lax.update(window.scrollY)
    }

    render() {
        return (
            <section className="about-area-two ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h2>C’est reparti pour une nouvelle édition du Congrès de pédiatrie</h2>

                                <p>  qui se déroulera les 13 et 14 octobre avec une double possibilité d’accès à l’hôtel Monotol (Daar el barka) en présentiel et en ligne sur la plateforme YATA medical.</p>


                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/about3.jpg")} className="about-img1" alt="about" />

                             </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;